"use strict";

const toggle = document.querySelector(".toggle");
const menu = document.querySelector(".menu");
const shortIntro = document.querySelector(".start-intro"); // Select the short intro element

/* Toggle mobile menu */
function toggleMenu() {
  if (menu.classList.contains("active")) {
    menu.classList.remove("active");
    toggle.querySelector("a").innerHTML = "<i class='fas fa-bars'></i>";
    showShortIntro(); // Show the intro when menu is closed
  } else {
    menu.classList.add("active");
    toggle.querySelector("a").innerHTML = "<i class='fas fa-times'></i>";
    hideShortIntro(); // Hide the intro when menu is active
  }
}

/* Event Listener */
toggle.addEventListener("click", toggleMenu);

function hideShortIntro() {
  if (shortIntro) {
    shortIntro.style.opacity = 0; // Hide the short intro
  }
}

function showShortIntro() {
  if (shortIntro) {
    shortIntro.style.opacity = 1; // Show the short intro
  }
}

// Elements reveal on scroll
function scrollTrigger(selector, options = {}) {
  let elements = document.querySelectorAll(selector);
  elements = Array.from(elements);
  elements.forEach((element) => {
    addObserver(element, options);
  });
}

function addObserver(element, options) {
  if (!("IntersectionObserver" in window)) {
    if (options.revealCallback) {
      options.revealCallback(element);
    } else {
      element.classList.add("active");
    }
    return;
  }

  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (options.revealCallback) {
          options.revealCallback(element);
        } else {
          element.classList.add("active");
        }
        observer.unobserve(element);
      }
    });
  }, options);

  observer.observe(element);
}

// Example usage:
scrollTrigger(".scroll-reveal", {
  rootMargin: "-100px",
});

// Accordion effect
const btns = document.querySelectorAll(".acc-btn");

function accordion() {
  const answer = this.nextElementSibling;
  if (answer.style.maxHeight) answer.style.maxHeight = null;
  else answer.style.maxHeight = answer.scrollHeight + "px";
}

// Event for accordion buttons
btns.forEach((el) => el.addEventListener("click", accordion));

// Navbar color change on scroll
const navbar = document.querySelector("nav");

window.addEventListener("scroll", () => {
  if (window.scrollY > 0) {
    navbar.classList.add("nav-fixed");
    navbar.style.backgroundColor = "#F8F2EE";
  } else {
    navbar.classList.remove("nav-fixed");
    navbar.style.background = "transparent";
  }
});

// Function to check if an element is in the viewport
function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// Function to add the reveal class when in the viewport
function revealOnScroll() {
  const revealElements = document.querySelectorAll(".scroll-reveal");
  revealElements.forEach((el) => {
    if (isInViewport(el)) {
      el.classList.add("reveal");
    }
  });
}

// Attach event listener to the window's scroll event for reveal effects
window.addEventListener("scroll", revealOnScroll);
